import * as React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ReactMarkdown from 'react-markdown'

import { descblockImg, descHeader, descTextblock } from './description-block.module.css'


const DescriptionBlock = ({ imageFile, titleText, descText }) => {

    const image = getImage(imageFile)

    return (

        <div>
            <GatsbyImage image={image} alt="" className={`rounded-1 ${descblockImg}`} />
            <h3 className={descHeader}>{titleText}</h3>
            <ReactMarkdown className={descTextblock} children={descText} />
        </div>

    )
}
export default DescriptionBlock