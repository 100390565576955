import * as React from 'react'
import { Table } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown'

import { specFieldValue, markdownImage } from './spec-list.module.css'


const SpecList = ({ specFields }) => {

    return (

        <Table striped className={`m-0 rounded text-start`}>
            <tbody>
                {
                    specFields.map(spec => (
                        <tr key={spec.id}>
                            <td>{spec.fieldname}</td>
                            <td className={specFieldValue}><ReactMarkdown className={markdownImage} children={spec.fieldvalue}/></td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>

    )
}
export default SpecList