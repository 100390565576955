import * as React from 'react'

import { Helmet } from "react-helmet";

import { boardTextMain } from './board-logo.module.css'

// Note to self:
// For now just use text
// Later switch to handle SVG

const BoardLogo = ({ mainText }) => {

    return (
        <div>
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300&display=swap" rel="stylesheet"/>
            </Helmet>
            <p className={`${boardTextMain} mb-0`}>{mainText}</p>
        </div>

    )
}
export default BoardLogo