import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Card } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBanner from '../../components/header-banner'
import BoardLogo from '../../components/board-logo'
import ShowcaseBlock from '../../components/showcase-block'
import DescriptionBlock from '../../components/description-block'
import SpecList from '../../components/spec-list'
import DownloadsList from '../../components/downloads-list'

import { animate } from '../../styles/common.module.css'
import { introBlock, introText, removeChildMargin } from './strapi-board.module.css'


const BoardViewPage = ({ data }) => {

    const filesPaneRef = React.useRef(null);
    let onFilesButtonClick = null;
    if (data.strapiBoard.downloads.length > 0) {
        onFilesButtonClick = () => filesPaneRef.current.scrollIntoView();
    }

    const specsPaneRef = React.useRef(null);
    // let onSpecsButtonClick = null;
    // if (data.strapiBoard.specblock.specfield.length > 0) {
    //     onSpecsButtonClick = () => specsPaneRef.current.scrollIntoView();
    // }

    return (
        <div>

            <MainLayout pageTitle={data.strapiBoard.boardname}>

                {/* SEO */}

                <Seo title={data.strapiBoard.boardname} description={data.strapiBoard.displayslogan} imageUrl={data.strapiBoard.cover.localFile.childImageSharp.fixed.src}></Seo>

                {/* Header */}

                <HeaderBanner imageFile={data.strapiBoard.cover.localFile}>
                    {/* <h1>Hello world!</h1> */}
                </HeaderBanner>


                {/* Intro boardname and text */}

                <Container className={`text-center ${animate} ${introBlock}`}>
                    <BoardLogo mainText={data.strapiBoard.introblock.displayname} />
                    <ReactMarkdown className={` ${introText}`} children={data.strapiBoard.introblock.displayslogan} />
                </Container>


                {/* Showcase block */}

                <Container className={animate}>
                    <ShowcaseBlock board={data.strapiBoard} images={data.strapiBoard.introblock.gallery} updates={data.allStrapiUpdate.nodes} onFilesButtonClick={() => onFilesButtonClick()} filesDontExist={data.strapiBoard.downloads.length < 1} />
                </Container>


                {/* Description blocks */}

                <Container className={`text-center ${animate}`}>

                    {
                        data.strapiBoard.descriptionblock.map(block => (
                            <DescriptionBlock key={block.id} imageFile={block.image.localFile} titleText={block.header} descText={block.paragraph} />
                        ))
                    }

                </Container>


                {/* Specs */}

                {
                    data.strapiBoard.specblock.specfield.length > 0 &&
                    <Container className={`text-center`}>
                        <Card className={`mb-5 shadow rounded`} ref={specsPaneRef}>
                            <Card.Header>
                                <h3 className={`mt-3 mb-3`}>Specifications</h3>
                            </Card.Header>
                            {
                                data.strapiBoard.specblock.headerimage && <Card.Img as={GatsbyImage} image={getImage(data.strapiBoard.specblock.headerimage.localFile)} alt="" variant="top" />
                            }
                            <SpecList specFields={data.strapiBoard.specblock.specfield} />
                            {
                                data.strapiBoard.specblock.additionalnotes &&
                                <Card.Footer className={`${removeChildMargin} pt-3 pb-3`}>
                                    <ReactMarkdown children={data.strapiBoard.specblock.additionalnotes} />
                                </Card.Footer>
                            }

                        </Card>
                    </Container>
                }


                {/* Downloads */}

                {
                    data.strapiBoard.downloads.length > 0 &&
                    <Container className={`text-center`}>
                        <Card className={`mb-5 shadow rounded`} ref={filesPaneRef}>
                            <Card.Header>
                                <h3 className={`mt-3 mb-3`}>Files and Resources</h3>
                            </Card.Header>
                            <DownloadsList downloadsList={data.strapiBoard.downloads} />
                        </Card>
                    </Container>
                }




            </MainLayout>
        </div>
    )
}

export const query = graphql`
query($id: String, $slug: String) {
    strapiBoard(id: { eq: $id }) {
        boardname
        slogan
        cover {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        aspectRatio: 1.618
                        transformOptions: { fit: COVER, cropFocus: CENTER }
                        placeholder: BLURRED
                        width: 750
                        quality: 90
                    )
                    fixed(width: 800, toFormat: JPG, quality: 95) {
                        src
                    }
                }
            }
        }
        introblock {
            displayname
            displayslogan
            gallery {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            aspectRatio: 1.618
                            transformOptions: { fit: COVER, cropFocus: CENTER }
                            placeholder: BLURRED
                            quality: 98
                        )
                    }
                }
            }
        }
        descriptionblock {
            id
            header
            paragraph
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            aspectRatio: 1.618
                            transformOptions: { fit: COVER, cropFocus: CENTER }
                            placeholder: BLURRED
                            width: 750
                            quality: 95
                        )
                    }
                }
            }
        }
        buttons {
            displaytext
            link
            id
        }
        downloads {
            id
            file {
                url
            }
            title
        }
        specblock {
            headerimage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            quality: 95
                        )
                    }
                }
            }
            specfield {
                id
                fieldname
                fieldvalue
            }
            additionalnotes
        }
    }
    allStrapiUpdate(sort: {fields: changedate, order: DESC}, filter: {board: {slug: {eq: $slug}}}) {
        nodes {
            id
            changedate
            state
            updatecontents
        }
        
    }
}
  
  
`

export default BoardViewPage