import * as React from 'react'
import { Carousel, Card, Button, Row, Col, Collapse } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
// import { BgImage } from 'gbimage-bridge'


import ReactMarkdown from 'react-markdown'

import UpdatesList from './updates-list'

import { statePane, latestStatusText, showcaseBlockCard, buttonListButtons } from './showcase-block.module.css'


class ConditionalUpdatesTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            show: props.show,
            updates: props.updates,
            buttonText: "Show Full Update History"
        };
    }

    handleClick() {

        if (this.state.collapse) {
            this.setState({ buttonText: "Show Full Update History", collapse: !this.state.collapse });
        } else {
            this.setState({ buttonText: "Hide Update History", collapse: !this.state.collapse });
        }

    }

    render() {
        if (this.state.show) {
            return (
                <div>
                    <Button
                        variant="outline-primary"
                        onClick={() => this.handleClick()}
                        aria-controls="collapse-updates-list"
                        aria-expanded={this.state.collapse}>
                        {this.state.buttonText}
                    </Button>
                    <Collapse in={this.state.collapse}>
                        <div className={`card border-secondary p-0 overflow-hidden mt-3`} id="collapse-updates-list">
                            <UpdatesList updatesList={this.state.updates} />
                        </div>
                    </Collapse>
                </div>
            );
        } else {
            return (<div />);
        }
    }
}

const GreetingBlock = ({ images, board, updates, onFilesButtonClick, filesDontExist }) => {

    // const latestUpdate = updateData;

    // Make updates list

    let showUpdates = false;
    let latestStatus = "---";
    let latestUpdate = "No updates exist for this project.";
    let lastUpdated = "---";

    if (updates && updates.length > 0) {
        latestStatus = updates[0].state;
        latestUpdate = updates[0].updatecontents;
        lastUpdated = updates[0].changedate;
        showUpdates = true;
    }

    // Make buttons list

    let buttonsList = [];

    if (board.buttons && board.buttons.length > 0) {
        buttonsList = board.buttons;
    } else {
        buttonsList.push({ displaytext: "No links for project", link: "", id: 1 })
    }



    return (
        <Card className={`shadow rounded ${showcaseBlockCard}`}>


            {/* Top image carousel */}

            <Carousel className={`card-img-top`}>

                {
                    images.map(imageFile => (

                        <Carousel.Item key={imageFile.id}>
                            <GatsbyImage loading={"eager"} image={imageFile.localFile.childImageSharp.gatsbyImageData} alt="" className={`rounded-top w-100 d-block h-100`} />
                            {/* <div> */}
                            {/* <BgImage image={imageFile.localFile.childImageSharp.gatsbyImageData} className={`rounded-top ${headerImage}`} /> */}
                            {/* </div> */}
                        </Carousel.Item>

                    ))
                }

            </Carousel>


            {/* Bottom row of two cols */}

            <Row className={`g-0`}>


                {/* Left update/state pane */}

                <Col lg={8} className={`text-md-start text-center justify-content-md-start justify-content-center`}>
                    <div className={`card-body d-flex flex-column ${statePane}`}>

                        <h4 className={`pb-1`}>Current Project Status: {latestStatus}</h4>
                        <ReactMarkdown className={latestStatusText} children={latestUpdate} />
                        <small className={`text-muted pb-4`}>Last updated {lastUpdated}</small>

                        <ConditionalUpdatesTable show={showUpdates} updates={updates} />


                    </div >

                </Col >


                {/* Right buttons pane */}

                <Col lg={4} className={`card-footer border-start border-end`}>
                    <Row className={`h-auto`}>


                        {/* The buttons provided by the board button entries*/}

                        {
                            buttonsList.map(button => {
                                return button.link ?
                                    (<Col xs={12} key={button.id} className={`h-auto `}>
                                        <Button className={`w-100 ${buttonListButtons}`} href={button.link}>{button.displaytext}</Button>
                                    </Col>)
                                    :
                                    (<Col xs={12} key={button.id}>
                                        <Button className={`w-100 ${buttonListButtons}`} key={button.id} disabled>{button.displaytext}</Button>
                                    </Col>)
                            })
                        }

                        {/* The files and resources button to scroll to the view at parent */}

                        {!filesDontExist &&
                            <Col xs={12} className={`h-auto `}>
                                <Button className={`w-100 ${buttonListButtons}`} onClick={() => onFilesButtonClick()}>Files and Resources</Button>
                            </Col>
                        }

                    </Row>
                </Col>

            </Row>

        </Card >

    )
}
export default GreetingBlock